// ANCHOR Model
import createModel, { createSelector, createSelectors } from 'react-scoped-model';

// ANCHOR SWR
import useSWR from 'swr';

// ANCHOR API
import { getCourse } from '@lyon/utils/api/course';

// ANCHOR Results
import { ICourseResult, PublishStatus } from '@lyon/utils/api/results/course';

// ANCHOR Utils
import { tuple } from '@lyon/utils/tuple';

interface IProps {
  id: string;
  initialData?: ICourseResult;
}

export const CourseData = createModel(({ id, initialData }: IProps) => {
  const response = useSWR(
    `/courses/${id}`,
    () => getCourse({ courseId: id }),
    {
      initialData,
      revalidateOnMount: true,
    },
  );

  return {
    ...response,
    check: {
      isDraft: response.data?.publishStatus === PublishStatus.DRAFT,
      isPublished: response.data?.publishStatus === PublishStatus.PUBLISHED,
      isClosed: response.data?.publishStatus === PublishStatus.CLOSED,
    },
  };
}, {
  displayName: 'Models.CourseData',
});

export const useCourseData = createSelector(
  CourseData,
  (state) => state.data,
);

export const useCourseDataUpdate = createSelector(
  CourseData,
  (state) => state.mutate,
);

export const useCourseDataInfo = createSelectors(
  CourseData,
  ({ data }) => tuple(
    data?.id,
    data?.title,
    data?.description,
    data?.courseDisplayPhotoUuid,
  ),
);

export const useCourseDataShare = createSelectors(
  CourseData,
  ({ data }) => tuple(
    data?.id,
    data?.title,
    data,
  ),
);

export const useCourseDataId = createSelector(
  CourseData,
  ({ data }) => data?.id,
);

export const useCourseDataTitle = createSelector(
  CourseData,
  ({ data }) => data?.title,
);

export const useCourseDataCoverImage = createSelector(
  CourseData,
  ({ data }) => data?.courseDisplayPhotoUuid,
);

export const useCourseDataPrice = createSelector(
  CourseData,
  ({ data }) => data?.price,
);

export const useCourseDataPaymentLink = createSelector(
  CourseData,
  ({ data }) => data?.paymentLink,
);

export const useCourseDataPublishStatus = createSelector(
  CourseData,
  ({ data }) => data?.publishStatus,
);

export const useCourseDataDescription = createSelector(
  CourseData,
  ({ data }) => data?.description,
);

export const useCourseDataSellType = createSelector(
  CourseData,
  ({ data }) => data?.sellType,
);

export const useCourseDataDisplayStudents = createSelector(
  CourseData,
  ({ data }) => data?.displayStudents,
);

export const useCourseDataConsumablePeriod = createSelector(
  CourseData,
  ({ data }) => data?.consumablePeriod,
);

export const useCourseDataRequireSequential = createSelector(
  CourseData,
  ({ data }) => data?.requireSequentialProgress,
);

export const useCourseDataVideoId = createSelector(
  CourseData,
  ({ data }) => data?.coursePreviewVideoUrl,
);

export const useCourseDataPreviewVideo = createSelectors(
  CourseData,
  ({ data }) => tuple(
    data?.courseDisplayPhotoUuid,
    data?.coursePreviewVideoUrl,
    data?.title,
  ),
);

export const useCourseDataObjectives = createSelector(
  CourseData,
  ({ data }) => data?.objectives,
);

export const useCourseDataGains = createSelector(
  CourseData,
  ({ data }) => data?.gains,
);

export const useCourseDataRequirements = createSelector(
  CourseData,
  ({ data }) => data?.requirements,
);

export const useCourseDataLecturers = createSelector(
  CourseData,
  ({ data }) => data?.lecturers,
);

export const useCourseDataCommunity = createSelectors(
  CourseData,
  ({ data }) => tuple(
    data?.lyonCommunity,
    data?.communityDescription,
  ),
);

export const useCourseDataArchived = createSelector(
  CourseData,
  ({ data }) => data?.isArchived,
);

export const useCourseDataDraft = createSelector(
  CourseData,
  ({ check }) => check?.isDraft,
);

export const useCourseDataPublished = createSelector(
  CourseData,
  ({ check }) => check?.isPublished,
);

export const useCourseDataStudentCount = createSelector(
  CourseData,
  ({ data }) => data?.studentCount,
);

export const useCourseDataModuleCount = createSelector(
  CourseData,
  ({ data }) => data?.moduleCount ?? 0,
);

export const useCourseDataProviderId = createSelector(
  CourseData,
  ({ data }) => data?.providerId,
);
