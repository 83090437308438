import { CardOverrides } from 'baseui/card';
import { DARK_MUTED } from '@lyon/utils/theme/colors/darkmuted';

export const CARD: CardOverrides = {
  Root: {
    style: {
      backgroundColor: DARK_MUTED.darkmuted700,
      borderLeftStyle: 'solid',
      borderRightStyle: 'solid',
      borderTopStyle: 'solid',
      borderBottomStyle: 'solid',
      borderBottomColor: DARK_MUTED.darkmuted400,
      borderTopColor: DARK_MUTED.darkmuted400,
      borderLeftColor: DARK_MUTED.darkmuted400,
      borderRightColor: DARK_MUTED.darkmuted400,
      borderLeftWidth: '1px',
      borderRightWidth: '1px',
      borderTopWidth: '1px',
      borderBottomWidth: '1px',
    },
  },
  Contents: {
    style: {
      marginTop: '16px',
      marginLeft: '16px',
      marginRight: '16px',
      marginBottom: '16px',
    },
  },
};
