// ANCHOR React
import React from 'react';

// ANCHOR Next
import Link from 'next/link';

// ANCHOR Base
import { styled } from 'baseui';
import { StyledLink } from 'baseui/link';

// ANCHOR Styles
import { NAV_ITEM, LINK } from './styles';

const ListItem = styled('li', NAV_ITEM);

interface IProps {
  children: React.ReactNode;
  isActive: boolean;
  href: string;
}

export const LyonSidebarManageNavItem = ({
  children, isActive = false, href,
}: IProps) => (
  <ListItem $isActive={isActive}>
    <Link href={href} passHref>
      <StyledLink $style={LINK}>
        { children }
      </StyledLink>
    </Link>
  </ListItem>
);
