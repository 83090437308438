// ANCHOR React
import React from 'react';
import Image from 'next/image';

interface BlurhashedProps {
  src: string;
  alt: string;
  // className: string;
}

export function Img(
  { src, alt }: BlurhashedProps,
): JSX.Element {
  return (
    <Image
      layout="fill"
      src={src}
      alt={alt}
    />
  );
}
