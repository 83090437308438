// ANCHOR React
import React, { ReactNode } from 'react';

// ANCHOR Utils
import { SidebarContentType } from '@lyon/utils/interface/sidebar';

// ANCHOR Models
import { SidebarContent } from '@lyon/scoped-models/sidebar/SidebarContent';

// ANCHOR Next
import dynamic from 'next/dynamic';

// ANCHOR Import Scheduler
import { importForInteractive } from 'import-scheduler';

// ANCHOR Components
import { LyonLayout } from './base/LyonLayout';
import { LyonContent } from './content/LyonContent';
import { LyonNavBar } from './navbar/LyonNavBar';
import { LyonSidebar } from './sidebar/LyonSidebar';
import { PageProvider } from './utils/Providers';
import { LyonAuthModal } from './auth-modal';

const LyonOnboardModal = dynamic(
  async () => {
    await importForInteractive();
    const mod = await import('./onboard-modal/LyonOnboardModal');
    return mod.LyonOnboardModal;
  }, {
    ssr: false,
  },
);

interface ILyonPageProps {
  hideSidebar?: boolean;
  useSidebarDrawer?: boolean;
  sidebarContent?: SidebarContentType;
  children: ReactNode;
  hasScroll?: boolean;
}

export function LyonPage(
  {
    hideSidebar,
    useSidebarDrawer,
    sidebarContent = 'default',
    children,
    hasScroll = true,
  }: ILyonPageProps,
) {
  return (
    <PageProvider>
      <LyonLayout>
        <LyonContent hasScroll={hasScroll} useSidebarDrawer={useSidebarDrawer}>
          { children }
        </LyonContent>
        <SidebarContent.Provider content={sidebarContent}>
          <LyonSidebar
            hideInitial={hideSidebar}
            useDrawer={useSidebarDrawer}
          />
        </SidebarContent.Provider>
        <LyonNavBar />
        <LyonAuthModal />
        <LyonOnboardModal />
      </LyonLayout>
    </PageProvider>
  );
}
