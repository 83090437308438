// ANCHOR React
import React, { memo } from 'react';

// ANCHOR React Icons
import { Icon } from 'react-icons-kit';

// ANCHOR Next
import { useRouter } from 'next/router';

// ANCHOR Base
import { styled } from 'baseui';
import { Block } from 'baseui/block';
import { LabelMedium } from 'baseui/typography';

// ANCHOR Models
import { useSidebarManageContent } from '@lyon/scoped-models/sidebar/SidebarManageContent';
import { ProviderCheckManager } from '@lyon/scoped-models/ProviderCheckManager';

// ANCHOR Utils
import { getQueryPID } from '@lyon/utils/extras/getQueryPID';

// ANCHOR Components
import { LyonSidebarManageNavItem } from './LyonSidebarManageNavItem';

// ANCHOR Constants
import { MANAGE_LINK_LOOKUP } from './constants';

// ANCHOR Styles
import { NAVLIST, PARAGRAPH, NAVGRID } from './styles';

const NavList = styled('ul', NAVLIST);
const Paragraph = styled('p', PARAGRAPH);
const NavGrid = styled(Block, NAVGRID);

export const LyonSidebarManageNav = memo(() => {
  const sidebarManagement = useSidebarManageContent();
  const isProvider = ProviderCheckManager.useSelector((state) => state.data);
  const currentRoute = useRouter().asPath;
  const { pid } = useRouter().query;
  const entityId = getQueryPID(pid);

  return (
    <NavList>
      {
        MANAGE_LINK_LOOKUP[sidebarManagement].map((link) => {
          const href = link.href(entityId);

          if (!isProvider && !link.isInstructorAllowed) {
            return null;
          }

          return (
            <LyonSidebarManageNavItem
              key={link.key}
              isActive={currentRoute === href}
              href={href}
            >
              <NavGrid>
                <Block>
                  <Icon size={24} icon={link.icon} />
                </Block>
                <Block>
                  <LabelMedium>{link.label}</LabelMedium>
                  <Paragraph>{link.caption}</Paragraph>
                </Block>
              </NavGrid>
            </LyonSidebarManageNavItem>
          );
        })
      }
    </NavList>
  );
});
