// ANCHOR React
import React from 'react';

// ANCHOR Interface
import { SidebarContentLookup } from '@lyon/utils/interface/sidebar';

// ANCHOR Components
import { SidebarManageContent } from '@lyon/scoped-models/sidebar/SidebarManageContent';
import { LyonSidebarManageContent } from './LyonSidebarManageContent';
import { LyonSidebarMainContent } from './LyonSidebarMainContent';

export const SIDEBAR_CONTENT_LOOKUP: SidebarContentLookup = {
  default: <LyonSidebarMainContent />,
  courseManagement: (
    <SidebarManageContent.Provider content="courseManagement">
      <LyonSidebarManageContent />
    </SidebarManageContent.Provider>
  ),
};
