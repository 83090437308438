// ANCHOR React
import React, { memo, useEffect } from 'react';

// ANCHOR Base
import { Block } from 'baseui/block';
import { StyledLink } from 'baseui/link';
import {
  Popover, PLACEMENT, TRIGGER_TYPE, ACCESSIBILITY_TYPE,
} from 'baseui/popover';

// ANCHOR Graph State
import {
  useOnBoardTooltipVisibility,
  useOnBoardTooltipVisibilityHide,
} from '@lyon/nodes/global/on-board-tooltip-visibility-node';
import { useOnBoardModalVisibilityShow } from '@lyon/nodes/global/on-board-modal-visibility-node';

// ANCHOR Hooks
import { useConstant } from '@lyon/utils/hooks/useConstant';

// ANCHOR Compoments
import { LyonSidebarPopoverContent } from './LyonSidebarPopoverContent';

// ANCHOR styles
import { PADDING, POPOVER, LINK } from './styles';

// ANCHOR Constants
import { TITLE } from './constants';

export const LyonSidebarModalVideo = memo(() => {
  const isOpen = useOnBoardTooltipVisibility();
  const hideTooltip = useOnBoardTooltipVisibilityHide();
  const showModal = useOnBoardModalVisibilityShow();

  const content = useConstant(() => <LyonSidebarPopoverContent />);

  useEffect(() => {
    if (isOpen) {
      const timeout = setTimeout(() => {
        hideTooltip();
      }, 3000);

      return () => clearTimeout(timeout);
    }

    return undefined;
  }, [hideTooltip, isOpen]);

  return (
    <Block padding={PADDING}>
      <Popover
        isOpen={isOpen}
        content={content}
        placement={PLACEMENT.left}
        triggerType={TRIGGER_TYPE.hover}
        accessibilityType={ACCESSIBILITY_TYPE.tooltip}
        overrides={POPOVER}
        showArrow
        renderAll
      >
        <StyledLink
          $style={LINK}
          onClick={showModal}
        >
          {TITLE}
        </StyledLink>
      </Popover>
    </Block>
  );
});
