import { THEME } from '@lyon/utils/theme';
import { PopoverOverrides } from 'baseui/popover';

export const POPOVER: PopoverOverrides = {
  Body: {
    style: {
      position: 'absolute',
      left: '128px',
      borderLeftStyle: 'solid',
      borderRightStyle: 'solid',
      borderTopStyle: 'solid',
      borderBottomStyle: 'solid',
      borderLeftWidth: '1px',
      borderRightWidth: '1px',
      borderTopWidth: '1px',
      borderBottomWidth: '1px',
      borderBottomColor: THEME.colors.darkmuted50,
      borderTopColor: THEME.colors.darkmuted50,
      borderLeftColor: THEME.colors.darkmuted50,
      borderRightColor: THEME.colors.darkmuted50,
      backgroundColor: THEME.colors.backgroundAlt,
    },
  },
  Arrow: {
    style: {
      borderLeftStyle: 'solid',
      borderBottomStyle: 'solid',
      borderLeftWidth: '1px',
      borderBottomWidth: '1px',
      borderBottomColor: THEME.colors.darkmuted50,
      borderTopColor: THEME.colors.darkmuted50,
      borderLeftColor: THEME.colors.darkmuted50,
      borderRightColor: THEME.colors.darkmuted50,
      backgroundColor: THEME.colors.backgroundAlt,
    },
  },
  Inner: {
    style: {
      backgroundColor: THEME.colors.backgroundAlt,
    },
  },
};

export const LINK = {
  textDecoration: 'underline',
  cursor: 'pointer',
};

export const PADDING = '8px';
