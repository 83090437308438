// ANCHOR Model
import createModel from '@lxsmnsyc/react-scoped-model';

// ANCHOR SWR
import useSWR from 'swr';

// ANCHOR API
import { getProviderRights } from '@lyon/utils/api/provider';

// ANCHOR Graph State
import { useAuthToken } from '@lyon/nodes/firebase/auth/auth-token-node';
import { useAuthState } from '@lyon/nodes/firebase/auth/auth-state-node';
import { getAdminCheck } from '@lyon/utils/api/user';

interface IProps {
  providerId: string;
}

export const ProviderCheckManager = createModel(
  ({ providerId }: IProps) => {
    const token = useAuthToken();
    const user = useAuthState();

    /* NOTE: This is used instead of `useAuthAdmin` because `useAuthAdmin` forces
     * the page to refetch when the user goes to different tabs
     */
    const { data: isAdmin } = useSWR<boolean | undefined>(
      (
        (user && token)
          ? `/auth/${user.uid}/admin`
          : null
      ),
      () => (
        (user && token)
          ? getAdminCheck({ token, userId: user.uid })
          : undefined
      ),
    );

    const response = useSWR<boolean | undefined>(
      (
        (!isAdmin && user && token)
          ? `/provider/${providerId}/check-manager`
          : null
      ),
      () => (
        (!isAdmin && user && token)
          ? getProviderRights({
            providerId,
            token,
            userId: user.uid,
          })
          : undefined
      ),
    );

    const type = typeof token === 'string'
      ? response.data
      : token;

    return {
      data: isAdmin || (response.data ?? type),
    };
  },
  {
    displayName: 'Models.ProviderCheckManager',
  },
);
