// ANCHOR React
import React, { memo } from 'react';

// ANCHOR Base
import { withStyle } from 'baseui';
import { LabelMedium } from 'baseui/typography';

// ANCHOR Components
import { Skeleton } from '@lyon/components/utils/Skeleton';

// ANCHOR Styles
import { HEADING, TITLE_SKELETON } from './styles';

const TitleSkeleton = withStyle(Skeleton, TITLE_SKELETON);

interface IProps {
  title?: string;
}

// TODO @git-ced use <ContentTitle />
export const SidebarManageTitle = memo(({ title }: IProps) => {
  if (title) {
    return (
      <LabelMedium overrides={HEADING}>
        { title }
      </LabelMedium>
    );
  }

  return <TitleSkeleton />;
});
