import { StyleObject } from 'styletron-react';
import { ListOverrides } from 'baseui/list';

export const LIST_ITEM: ListOverrides = {
  Root: {
    style: {
      padding: 0,
      backgroundColor: 'auto',
    },
  },
  ArtworkContainer: {
    style: {
      width: 'fit-content',
      paddingTop: '8px',
      paddingBottom: '8px',
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  Content: {
    style: {
      width: '100%',
      paddingLeft: '8px',
      height: 'auto',
      fontSize: '0.85rem',
      borderBottomWidth: 0,
      borderBottomStyle: 'none',
      borderBottomColor: 'none',
      boxSizing: 'border-box',
    },
  },
  EndEnhancerContainer: {
    /**
     * NOTE Since this is one (so far) of the many
     * that requires all components to be overriden,
     * this option is intentionally left empty so
     * that there won't be any compiler errors
     */
  },
};

export const LINK: StyleObject = {
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  fontWeight: 'normal',
  textDecoration: 'none',
};
