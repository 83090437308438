import { BlockOverrides } from 'baseui/block';

export const PADDING: BlockOverrides = {
  Block: {
    style: {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: '16px',
      paddingRight: '16px',
    },
  },
};
