// ANCHOR React
import React, { memo } from 'react';

// ANCHOR Base
import { StyledBody, StyledAction } from 'baseui/card';

// ANCHOR Components
import { SidebarSection } from '@lyon/components/utils/SidebarSection';
import { LyonSidebarApplyProviderButton } from './LyonSidebarApplyProviderButton';
import { LyonSidebarApplyProviderContainer } from './LyonSidebarApplyProviderContainer';
import { LyonSidebarApplyProviderContent } from './LyonSidebarApplyProviderContent';
import { LyonSidebarApplyProviderTitle } from './LyonSidebarApplyProviderTitle';

export const LyonSidebarApplyProvider = memo(() => (
  <SidebarSection>
    <LyonSidebarApplyProviderContainer>
      <StyledBody>
        <LyonSidebarApplyProviderTitle />
        <LyonSidebarApplyProviderContent />
      </StyledBody>
      <StyledAction>
        <LyonSidebarApplyProviderButton />
      </StyledAction>
    </LyonSidebarApplyProviderContainer>
  </SidebarSection>
));
