export const BLOCK = {
  Block: {
    style: {
      marginTop: '16px',
      marginLeft: '8px',
      marginRight: '8px',
      marginBottom: '16px',
      overflow: 'hidden',
    },
  },
};
