import { StyleObject } from 'styletron-react';
import { THEME } from '@lyon/utils/theme';

export const LINK = {
  marginBottom: '16px',
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',

  ...THEME.typography.font350,
};

export const LINK_SKELETON: StyleObject = {
  width: '48%',
  height: '16px',
  marginBottom: '16px',
};
