// ANCHOR React
import React, { memo } from 'react';

// ANCHOR Base
import { ParagraphMedium } from 'baseui/typography';

// ANCHOR Constants
import { TITLE } from './constants';

// TODO @git-ced use <ContentDescription />
export const LyonSidebarApplyProviderContent = memo(() => (
  <ParagraphMedium>{ TITLE }</ParagraphMedium>
));
