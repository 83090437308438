// ANCHOR Graph State
import { node } from 'graph-state';
import { useGraphNodeValue } from 'react-graph-state';

// ANCHOR Dependencies
import { authStateNode } from './auth-state-node';

const authRequiredNode = node<undefined | boolean>({
  key: 'authRequired',
  get: ({ get }) => {
    const user = get(authStateNode);

    if (typeof user === 'undefined') {
      return undefined;
    }

    return user != null;
  },
});

export function useAuthRequired() {
  return useGraphNodeValue(authRequiredNode);
}
