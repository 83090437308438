// ANCHOR Model
import { createPropsSelectorModel, createSelector } from 'react-scoped-model';

// ANCHOR Utils
import { SidebarContentType } from '@lyon/utils/interface/sidebar';

interface IProps {
  content: SidebarContentType;
}

export const SidebarContent = createPropsSelectorModel<IProps>({
  displayName: 'Models.SidebarContent',
});

export const useSidebarContent = createSelector(
  SidebarContent,
  ({ content }) => content,
);
