// ANCHOR React
import React, { memo } from 'react';

// ANCHOR Components
import { SidebarSection } from '@lyon/components/utils/SidebarSection';
import { LyonSidebarAboutLink } from './LyonSidebarAboutLink';
import { LyonSidebarModalVideo } from './LyonSidebarModalVideo';

export const LyonSidebarFooter = memo(() => (
  <SidebarSection>
    <LyonSidebarModalVideo />
    <LyonSidebarAboutLink />
  </SidebarSection>
));
