// ANCHOR React
import React, { memo } from 'react';

// ANCHOR Models
import { useCourseDataInfo } from '@lyon/scoped-models/CourseData';

// ANCHOR Components
import { SidebarManageDescription } from '@lyon/components/utils/SidebarManageDescription';
import { SidebarManageReturn } from '@lyon/components/utils/SidebarManageReturn';
import { SidebarManageThumbnail } from '@lyon/components/utils/SidebarManageThumbnail';
import { SidebarManageTitle } from '@lyon/components/utils/SidebarManageTitle';

export const LyonSidebarCourseManageInfoContent = memo(() => {
  const [id, title, description, displayPhotoUuid] = useCourseDataInfo();

  return (
    <>
      <SidebarManageReturn entity="course" id={id} />
      <SidebarManageThumbnail
        title={title}
        src={displayPhotoUuid}
      />
      <SidebarManageTitle title={title} />
      <SidebarManageDescription description={description} />
    </>
  );
});
