// ANCHOR React
import React, { memo } from 'react';

// ANCHOR Models
import { PublicProviderSubscriptions } from '@lyon/scoped-models/profile/PublicProviderSubscriptions';

// ANCHOR Utils
import { ucarecdnScaleCropOptimize } from '@lyon/utils/ucarecdn';

// ANCHOR Components
import { LyonEmptySubscriptions } from '@lyon/components/empty/LyonEmptySubscriptions';
import { SidebarSection } from '@lyon/components/utils/SidebarSection';
import { SidebarLabel } from '@lyon/components/utils/SidebarLabel';
import { SidebarMenu } from '@lyon/components/utils/SidebarMenu';
import { SidebarMenuItem } from '@lyon/components/utils/SidebarMenuItem';

// ANCHOR Constants
import { PROVIDER_SUBSCRIPTIONS } from './constants';

export const LyonSidebarSubscribedProviders = memo(() => {
  const providerSubscriptions = PublicProviderSubscriptions.useSelector((state) => state.data);

  return (
    <SidebarSection>
      <SidebarLabel value={PROVIDER_SUBSCRIPTIONS} />
      <SidebarMenu>
        {
          providerSubscriptions && providerSubscriptions.length !== 0
            ? (
              providerSubscriptions.map(({
                id, name, displayPhotoUuid,
              }) => (
                <SidebarMenuItem
                  key={id}
                  id={id}
                  name={name}
                  avatar={ucarecdnScaleCropOptimize('40x40', displayPhotoUuid)}
                />
              ))
            )
            : <LyonEmptySubscriptions />
        }
      </SidebarMenu>
    </SidebarSection>
  );
});
