// ANCHOR React
import { useCallback } from 'react';

// ANCHOR Graph Node
import { node } from 'graph-state';
import {
  useGraphNodeDispatch,
  useGraphNodeValue,
} from 'react-graph-state';

export const onBoardTooltipVisibilityNode = node({
  key: 'onBoardTooltipVisibility',
  get: false,
});

export function useOnBoardTooltipVisibility() {
  return useGraphNodeValue(onBoardTooltipVisibilityNode);
}

export function useOnBoardTooltipVisibilityDispatch() {
  return useGraphNodeDispatch(onBoardTooltipVisibilityNode);
}

export function useOnBoardTooltipVisibilityShow() {
  const dispatch = useOnBoardTooltipVisibilityDispatch();

  return useCallback(() => {
    dispatch(true);
  }, [dispatch]);
}

export function useOnBoardTooltipVisibilityHide() {
  const dispatch = useOnBoardTooltipVisibilityDispatch();

  return useCallback(() => {
    dispatch(false);
  }, [dispatch]);
}
