export const NAVLIST = {
  listStyleType: 'none',
  paddingLeft: 0,
  marginBottom: 0,
};

export const PARAGRAPH = {
  opacity: '0.8',
  marginTop: 0,
  marginBottom: 0,
  lineHeight: 'normal',
  fontSize: '10px',
};

export const NAVGRID = {
  display: 'grid',
  gridTemplateColumns: '40px 1fr',
  alignItems: 'center',
};
