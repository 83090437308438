import { StyleObject } from 'styletron-react';

export const THUMBNAIL: StyleObject = {
  height: '100%',
  width: '100%',
  objectFit: 'contain',
};

export const THUMBNAIL_SKELETON: StyleObject = {
  width: '100%',
  height: '100%',
};
