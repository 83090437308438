// ANCHOR React
import React, { memo } from 'react';

// ANCHOR Base
import { Block } from 'baseui/block';
import { StyledLink } from 'baseui/link';

// ANCHOR styles
import Link from 'next/link';
import { PADDING } from './styles';

// ANCHOR Constants
import { HREF, TITLE } from './constants';

export const LyonSidebarAboutLink = memo(() => (
  <Block padding={PADDING}>
    <Link href={HREF} passHref>
      <StyledLink>
        {TITLE}
      </StyledLink>
    </Link>
  </Block>
));
