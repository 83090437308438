// ANCHOR React
import React, { memo } from 'react';

// ANCHOR Components
import { SidebarSection } from '@lyon/components/utils/SidebarSection';
import { LyonSidebarManageInfo } from './LyonSidebarManageInfo';
import { LyonSidebarManageNav } from './LyonSidebarManageNav';

export const LyonSidebarManageContent = memo(() => (
  <SidebarSection>
    <LyonSidebarManageInfo />
    <LyonSidebarManageNav />
  </SidebarSection>
));
