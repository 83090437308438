// ANCHOR Base
import { withStyle } from 'baseui';
import {
  ALIGN,
  StyledNavigationList,
  StyledNavigationItem,
} from 'baseui/header-navigation';

// ANCHOR Components
import { LyonLogo } from '@lyon/components/logo/LyonLogo';
import { constant } from '@lyon/components/utils/constant';

const LogoContainer = withStyle(StyledNavigationItem, {
  lineHeight: '0px',
  paddingTop: 0,
  paddingLeft: '40px',
  paddingRight: 0,
  paddingBottom: 0,
});

export const LyonNavBarLeft = constant(() => (
  <StyledNavigationList $align={ALIGN.left}>
    <LogoContainer>
      <LyonLogo height="36" />
    </LogoContainer>
  </StyledNavigationList>
));
