// ANCHOR React
import * as React from 'react';

// ANCHOR Base
import { Block } from 'baseui/block';
import { HeaderNavigation } from 'baseui/header-navigation';

// ANCHOR Styles
import { HEADER_NAVIGATION, BLOCK } from './styles';

// ANCHOR Components
import { LyonNavBarLeft } from './LyonNavCenterLeft';
import { LyonNavBarCenter } from './LyonNavBarCenter';
import { LyonNavBarRight } from './LyonNavCenterRight';

export const LyonNavBar = React.memo(() => (
  <Block overrides={BLOCK}>
    <HeaderNavigation overrides={HEADER_NAVIGATION}>
      <LyonNavBarLeft />
      <LyonNavBarCenter />
      <LyonNavBarRight />
    </HeaderNavigation>
  </Block>
));
