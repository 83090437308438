// ANCHOR React
import React, { memo } from 'react';

// ANCHOR Next
import Link from 'next/link';

// ANCHOR Base
import { withStyle } from 'baseui';
import { ArrowLeft } from 'baseui/icon';
import { StyledLink } from 'baseui/link';
import { LabelMedium } from 'baseui/typography';

// ANCHOR Models
import { ProviderCheckManager } from '@lyon/scoped-models/ProviderCheckManager';

// ANCHOR Utils
import { capitalizeFirstLetter } from '@lyon/utils/extras/capitalizeFirstLetter';

// ANCHOR Components
import { Skeleton } from '@lyon/components/utils/Skeleton';

// ANCHOR Styles
import { LINK, LINK_SKELETON } from './styles';

const LinkSkeleton = withStyle(Skeleton, LINK_SKELETON);

interface IProps {
  id?: string;
  entity: string;
}

export const SidebarManageReturn = memo(({
  id, entity,
}: IProps) => {
  const isProvider = ProviderCheckManager.useSelector((state) => state.data);

  if (id) {
    const href = `/${entity}/${id}${(entity === 'course' && isProvider) ? '/manage' : ''}`;

    return (
      <Link
        href={href}
        passHref
      >
        <StyledLink $style={LINK} href="#">
          <ArrowLeft size={24} />
          <LabelMedium>
            {`Back to ${capitalizeFirstLetter(entity)} Page`}
          </LabelMedium>
        </StyledLink>
      </Link>
    );
  }

  return <LinkSkeleton />;
});
