import { DarkTheme } from 'baseui';
import { BlockOverrides } from 'baseui/block';
import { StyleObject } from 'styletron-react';

export const PARAGRAPH: BlockOverrides = {
  Block: {
    style: {
      color: DarkTheme.colors.mono200,
      marginTop: '4px',
      marginBottom: 0,
      lineHeight: 1.15,
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
    },
  },
};

export const PARAGRAPH_SKELETON: StyleObject = {
  width: '100%',
  height: '16px',
  marginTop: '8px',
};
