// ANCHOR React
import React from 'react';

// ANCHOR Base
import { Card } from 'baseui/card';

// ANCHOR Styles
import { CARD } from './styles';

interface IProps {
  children: React.ReactNode;
}

export function LyonSidebarApplyProviderContainer({ children }: IProps) {
  return (
    <Card overrides={CARD}>
      { children }
    </Card>
  );
}
