// ANCHOR React
import React, { memo } from 'react';

// ANCHOR Base
import { Block } from 'baseui/block';

// ANCHOR Models
import { useSidebarManageContent } from '@lyon/scoped-models/sidebar/SidebarManageContent';

// ANCHOR Styles
import { CONTAINER } from './styles';

// ANCHOR Constants
import { SIDEBAR_MANAGEMENT_LOOKUP } from './constant';

export const LyonSidebarManageInfo = memo(() => {
  const sidebarManagement = useSidebarManageContent();

  return (
    <Block overrides={CONTAINER}>
      {SIDEBAR_MANAGEMENT_LOOKUP[sidebarManagement]}
    </Block>
  );
});
