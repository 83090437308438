// ANCHOR React
import * as React from 'react';

// ANCHOR Base
import { Block } from 'baseui/block';

// ANCHOR Styles
import { BLOCK } from './styles';

interface ILyonBaseProps {
  children?: React.ReactNode;
}

/**
 * Base component of all pages
 *
 * Used for layout definition
 */
export function LyonLayout({ children }: ILyonBaseProps) {
  return (
    <Block overrides={BLOCK}>
      { children }
    </Block>
  );
}
