// ANCHOR React
import * as React from 'react';

// ANCHOR Base
import { Card } from 'baseui/card';
import { LabelSmall } from 'baseui/typography';

// ANCHOR Styles
import { CARD } from './styles';

// ANCHOR Constants
import { NOT_SUBSCRIBED } from './constants';

export const LyonEmptySubscriptions = React.memo(() => (
  <Card overrides={CARD}>
    <LabelSmall>
      {NOT_SUBSCRIBED}
    </LabelSmall>
  </Card>
));
