/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
// ANCHOR Payloads
import { IProfileResult } from './results/profile';
import { IProviderResult } from './results/provider';
import { IUpdateUserDetails } from './payloads/profile';

export async function getProfileById(
  id: string,
): Promise<IProfileResult | undefined> {
  const { sdk } = await import('../axios/graphql');
  const response = await sdk().GetUserById({
    id,
  });

  if (response.user) {
    return response.user as IProfileResult;
  }

  return undefined;
}

export async function getProfileByEmail(
  email: string,
): Promise<IProfileResult | undefined> {
  const { sdk } = await import('../axios/graphql');
  const response = await sdk().GetUserByEmail({
    email,
  });

  if (response.user.length) {
    return response.user[0] as IProfileResult;
  }

  return undefined;
}

export async function getProfileProviderSubscriptionsById(
  id: string,
): Promise<IProviderResult[]> {
  const { sdk } = await import('../axios/graphql');
  const response = await sdk().GetUserProviderSubscriptionsById({
    id,
  });

  if (response.user?.providerSubscriptions.length) {
    const providers = response.user.providerSubscriptions.map((item) => {
      const {
        subscribersAggregate,
        eventsAggregate,
        upcomingEventsAggregate,
        archivedEventsAggregate,
        industries,
        ...result
      } = item.provider;

      return {
        ...result,
        subscribersCount: subscribersAggregate.aggregate?.count,
        eventsCount: eventsAggregate.aggregate?.count,
        upcomingEventsCount: upcomingEventsAggregate.aggregate?.count,
        archivedEventsCount: archivedEventsAggregate.aggregate?.count,
        industries: industries.map((industry) => industry.industryName),
      } as IProviderResult;
    });

    return providers;
  }

  return [];
}

export async function updateUserDetails({
  token,
  id,
  displayName,
  displayPhotoUuid,
  city,
}: IUpdateUserDetails) {
  const { sdk } = await import('../axios/graphql');

  if (token) {
    await sdk(token).UpdateUserDetails({
      id,
      displayName,
      displayPhotoUuid: displayPhotoUuid ?? null,
      city: city ?? null,
    });
  }
}

export async function getUserNames(userIds: string[]) {
  const { sdk } = await import('../axios/graphql');

  return sdk().GetUserNames({
    userIds,
  });
}

export async function getUserInfos(userIds: string[]) {
  const { sdk } = await import('../axios/graphql');

  return sdk().GetUserInfos({
    userIds,
  });
}
