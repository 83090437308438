// ANCHOR Base
import { HeaderNavigationOverrides } from 'baseui/header-navigation';
import { BlockOverrides } from 'baseui/block';
import { SHADOWS } from '@lyon/utils/theme/colors/shadows';

// ANCHOR Override for the BaseUI HeaderNavigation component
export const HEADER_NAVIGATION: HeaderNavigationOverrides = {
  Root: {
    style: {
      height: '24px',
      borderBottomStyle: 'none',
    },
  },
};

export const BLOCK: BlockOverrides = {
  Block: {
    style: {
      /**
       * ANCHOR Grid column definition
       *
       * defines the grid column for the navbar block
       * to occupy
       */
      gridColumn: '1 / 3',

      /**
       * ANCHOR Grid row definition
       *
       * defines the grid row for the navbar block
       * to occupy
       */
      gridRow: '1 / 2',

      // ANCHOR Shadow
      boxShadow: SHADOWS.shadow600,
    },
  },
};
