// ANCHOR React
import React, { memo } from 'react';

// ANCHOR Img
import { Img } from '@lyon/components/image';

// ANCHOR Base
import { styled } from 'baseui';
import { AspectRatioBox, AspectRatioBoxBody } from 'baseui/aspect-ratio-box';

// ANCHOR Utils
import { ucarecdnScaleCropOptimize } from '@lyon/utils/ucarecdn';

// ANCHOR Styles
import { THUMBNAIL } from './styles';

const Thumbnail = styled(Img, THUMBNAIL);

interface IProps {
  title?: string;
  src?: string;
}

export const SidebarManageThumbnail = memo(({ title, src }: IProps) => (
  <AspectRatioBox aspectRatio={16 / 9}>
    <AspectRatioBoxBody
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Thumbnail
        src={ucarecdnScaleCropOptimize('1200x675', src)}
        alt={title ?? ''}
      />
    </AspectRatioBoxBody>
  </AspectRatioBox>
));
