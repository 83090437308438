// ANCHOR React
import React, { memo } from 'react';

// ANCHOR Base
import { Block, Responsive, JustifyContent } from 'baseui/block';

// ANCHOR Next
import Link from 'next/link';
import { useRouter } from 'next/router';
import Image from 'next/image';

// ANCHOR Graph States
import { useAuthState } from '@lyon/nodes/firebase/auth/auth-state-node';

// ANCHOR Constants
import { IMG_ALT, IMG_SOURCE } from './constants';

// ANCHOR Styles
import { BLOCK } from './styles';

interface IProps {
  width?: string | number;
  height?: string | number;
  align?: Responsive<JustifyContent>;
}

const WIDTH_FACTOR = 267 / 150;
const HEIGHT_FACTOR = 150 / 267;

export const LyonLogo = memo(({ width, height, align }: IProps) => {
  let baseWidth = width;
  let baseHeight = height;
  const router = useRouter();
  const user = useAuthState();

  if (width == null && height) {
    baseWidth = Number.parseInt(height.toString(), 10) * WIDTH_FACTOR;
  }
  if (height == null && width) {
    baseHeight = Number.parseInt(width.toString(), 10) * HEIGHT_FACTOR;
  }
  if (baseWidth == null && baseHeight == null) {
    baseWidth = 267;
    baseHeight = 150;
  }
  return (
    <Block overrides={BLOCK} justifyContent={align ?? 'center'}>
      <Link href={String(router.query.redirectUrl || `/profile/${user?.uid ?? 'me'}`)}>
        <a>
          <Image
            src={IMG_SOURCE}
            alt={IMG_ALT}
            width={baseWidth}
            height={baseHeight}
            loading="eager"
          />
        </a>
      </Link>
    </Block>
  );
});
