// ANCHOR Base
import { BlockOverrides } from 'baseui/block';

export const LABEL: BlockOverrides = {
  Block: {
    style: {
      textTransform: 'uppercase',
      paddingLeft: '8px',
      fontSize: '0.78rem',
    },
  },
};
