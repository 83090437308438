// ANCHOR Model
import createModel from '@lxsmnsyc/react-scoped-model';

// ANCHOR SWR
import useSWR from 'swr';

// ANCHOR API
import { getProfileProviderSubscriptionsById } from '@lyon/utils/api/profile';

interface IProps {
  id: string;
}

export const PublicProviderSubscriptions = createModel(({ id }: IProps) => {
  const response = useSWR(
    `/profile/provider-subscriptions/${id}`,
    () => getProfileProviderSubscriptionsById(id),
  );

  return {
    ...response,
  };
}, {
  displayName: 'Models.PublicProviderSubscriptions',
});
