// ANCHOR React
import React, { memo } from 'react';

// ANCHOR Base
import { LabelLarge } from 'baseui/typography';

// ANCHOR Constants
import { TITLE } from './constants';

// ANCHOR Styles
import { LABEL } from './styles';

export const LyonSidebarApplyProviderTitle = memo(() => (
  <LabelLarge overrides={LABEL}>{ TITLE }</LabelLarge>
));
