import { BlockOverrides } from 'baseui/block';

import { BACKGROUND } from '@lyon/utils/theme/colors/background';
import { SHADOWS } from '@lyon/utils/theme/colors/shadows';

export const BLOCK: BlockOverrides = {
  Block: {
    style: {
      gridColumn: '1 / 2',
      gridRow: '2 / 3',
      backgroundColor: BACKGROUND.backgroundSidebar,
      overflowX: 'hidden',
      boxShadow: SHADOWS.shadow500,
    },
  },
};
