// ANCHOR React
import React, { memo } from 'react';

// ANCHOR Base
import { LabelXSmall } from 'baseui/typography';

// ANCHOR Styles
import { LABEL } from './styles';

interface IProps {
  value: string;
}

export const SidebarLabel = memo(({ value }: IProps) => (
  <LabelXSmall overrides={LABEL}>{ value }</LabelXSmall>
));
