// ANCHOR Model
import createModel, {
  createSelector, createSelectors,
} from 'react-scoped-model';

// ANCHOR SWR
import useSWR from 'swr';

// ANCHOR API
import { getProfileById } from '@lyon/utils/api/profile';

// ANCHOR Results
import { IProfileResult } from '@lyon/utils/api/results/profile';

// ANCHOR Utils
import { tuple } from '@lyon/utils/tuple';

interface IProps {
  id: string;
  initialData?: IProfileResult;
}

export const PublicUserProfile = createModel(({ id, initialData }: IProps) => {
  const response = useSWR(
    `/profile/${id}`,
    () => getProfileById(id),
    {
      initialData,
      revalidateOnMount: true,
    },
  );

  return {
    id,
    ...response,
  };
}, {
  displayName: 'Models.PublicUserProfile',
});

export const usePublicUserProfileData = createSelector(
  PublicUserProfile,
  ({ data }) => data,
);

export const usePublicUserProfileMutate = createSelector(
  PublicUserProfile,
  ({ mutate }) => mutate,
);

export const usePublicUserProfileId = createSelector(
  PublicUserProfile,
  (state) => state.id,
);

export const usePublicUserProfileAvatar = createSelectors(
  PublicUserProfile,
  (state) => tuple(
    state.data?.displayPhotoUuid,
    state.data?.displayName,
  ),
);

export const usePublicUserProfileDetails = createSelectors(
  PublicUserProfile,
  ({ data }) => tuple(
    data?.id,
    data?.displayPhotoUuid,
    data?.displayName,
    data?.city,
  ),
);

export const usePublicUserProfileName = createSelector(
  PublicUserProfile,
  (state) => state.data?.displayName,
);

export const usePublicUserProfilePhoneNumber = createSelector(
  PublicUserProfile,
  (state) => state.data?.phoneNumber,
);

export const usePublicUserProfileEmail = createSelector(
  PublicUserProfile,
  (state) => state.data?.email,
);
