// ANCHOR Next
import dynamic from 'next/dynamic';

// ANCHOR Import Scheduler
import { importForInteractive } from 'import-scheduler';

export const LyonAuthModal = dynamic(
  async () => {
    await importForInteractive();
    const mod = await import('./LyonAuthModal');
    return mod.LyonAuthModal;
  }, {
    ssr: false,
  },
);
