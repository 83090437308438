// ANCHOR React
import * as React from 'react';

// ANCHOR Base
import { Block } from 'baseui/block';

// ANCHOR Model
import { MobileView } from '@lyon/scoped-models/MobileView';
import { SidebarVisibility } from '@lyon/scoped-models/sidebar/SidebarVisibility';

// ANCHOR Components
import { Scrollbar } from '@lyon/components/utils/Scrollbar';

// ANCHOR Styles
import {
  BLOCK, DESKTOP_COLUMN, MOBILE_COLUMN, NO_SCROLL,
} from './styles';

interface ILyonContentProps {
  useSidebarDrawer?: boolean;
  hasScroll?: boolean;
  children: React.ReactNode;
}

export function LyonContent({
  useSidebarDrawer,
  children,
  hasScroll,
}: ILyonContentProps) {
  const isDesktop = MobileView.useSelector((state) => state.isDesktop);
  const visible = SidebarVisibility.useSelector((state) => state.state);

  // Memoize column definition
  const column = React.useMemo(
    () => ((isDesktop && visible && !useSidebarDrawer) ? DESKTOP_COLUMN : MOBILE_COLUMN),
    [isDesktop, visible, useSidebarDrawer],
  );

  return (
    <Block gridColumn={column} overrides={BLOCK}>
      <Scrollbar overrides={hasScroll ? undefined : NO_SCROLL}>
        {children}
      </Scrollbar>
    </Block>
  );
}
