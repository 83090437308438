import { BlockOverrides } from 'baseui/block';
import { BACKGROUND } from '@lyon/utils/theme/colors/background';

// ANCHOR Base

export const BLOCK: BlockOverrides = {
  Block: {
    style: {
      /**
       * ANCHOR Grid row definition
       *
       * dictates what grid row should the content occupy
       */
      gridRow: '2 / 3',

      // ANCHOR background color
      backgroundColor: BACKGROUND.backgroundContent,
    },
  },
};
/**
 * ANCHOR Grid column definition for mobile
 *
 * dictates what grid column should the content occupy
 * when on mobile view
 *
 * For layout definition, please refer to LyonBase
 */
export const MOBILE_COLUMN = '1 / 3';

/**
 * ANCHOR Grid column definition for desktop
 *
 * dictates what grid column should the content occupy
 * when on desktop view
 *
 * For layout definition, please refer to LyonBase
 */
export const DESKTOP_COLUMN = '2 / 3';

export const NO_SCROLL: BlockOverrides = {
  Block: {
    style: {
      overflowY: 'hidden',
    },
  },
};
