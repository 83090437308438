import { ButtonOverrides } from 'baseui/button';

export const BUTTON: ButtonOverrides = {
  BaseButton: {
    style: {
      borderTopRadius: '4px',
      borderLeftRadius: '4px',
      borderRightRadius: '4px',
      borderBottomRadius: '4px',
    },
  },
};

export const LINK = {
  textDecoration: 'none',
};
