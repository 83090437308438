// ANCHOR React
import React from 'react';

// ANCHOR Base
import { styled } from 'baseui';

// ANCHOR Styles
import { UL } from './styles';

const StyledUL = styled('ul', UL);

interface IProps {
  children: React.ReactNode;
}

export const SidebarMenu = ({ children }: IProps) => (
  <StyledUL>
    { children }
  </StyledUL>
);
