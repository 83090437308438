// ANCHOR React
import React, { memo } from 'react';

// ANCHOR Next
import Link from 'next/link';

// ANCHOR Base
import { Button, KIND, SIZE } from 'baseui/button';
import { StyledLink } from 'baseui/link';

// ANCHOR Constants
import { BUTTON_TITLE, HREF } from './constants';

// ANCHOR Styles
import { BUTTON, LINK } from './styles';

export const LyonSidebarApplyProviderButton = memo(() => (
  <Link href={HREF} passHref>
    <StyledLink $style={LINK}>
      <Button
        kind={KIND.primary}
        size={SIZE.default}
        overrides={BUTTON}
      >
        { BUTTON_TITLE }
      </Button>
    </StyledLink>
  </Link>
));
