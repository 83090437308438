// ANCHOR React Icons
import { fileText } from 'react-icons-kit/fa/fileText';
import { user } from 'react-icons-kit/fa/user';
import { checkSquareO } from 'react-icons-kit/fa/checkSquareO';
import { fileMovieO } from 'react-icons-kit/fa/fileMovieO';
import { calendar } from 'react-icons-kit/fa/calendar';
import { creditCard } from 'react-icons-kit/fa/creditCard';
import { certificate } from 'react-icons-kit/fa/certificate';

// ANCHOR Interfaces
import {
  ISidebarManageLink, SidebarManagementLinkLookup,
} from '@lyon/utils/interface/sidebar';

// ANCHOR Course Management Links
const COURSE_MANAGE_LINKS: ISidebarManageLink[] = [
  // SECTION Summary
  {
    key: 'course-summary',
    href: (id) => `/course/${id}/dashboard`,
    icon: fileText,
    label: 'Course Summary',
    caption: 'View basic information, quick statistics, and summarized results.',
    isInstructorAllowed: true,
  },
  // SECTION Content
  {
    key: 'manage-content',
    href: (id) => `/course/${id}/dashboard/content`,
    icon: fileMovieO,
    label: 'Manage Modules',
    caption: 'Edit whether your modules could be previewed, accessed or not.',
    isInstructorAllowed: false,
  },
  // SECTION Instructors
  {
    key: 'course-instructor',
    href: (id) => `/course/${id}/dashboard/instructors`,
    icon: user,
    label: 'Course Instructors',
    caption: 'Add users to this course with instructor-specific permissions.',
    isInstructorAllowed: false,
  },
  // SECTION Students
  {
    key: 'course-student',
    href: (id) => `/course/${id}/dashboard/students`,
    icon: user,
    label: 'Course Students',
    caption: 'Send invites, view your students’ progress, and give out certificates.',
    isInstructorAllowed: true,
  },
  // SECTION Certificates
  {
    key: 'manage-certificate',
    href: (id) => `/course/${id}/dashboard/certificates`,
    icon: certificate,
    label: 'Manage Certificates',
    caption: 'Create certificate templates that you can distribute later.',
    isInstructorAllowed: false,
  },
  // SECTION Responses
  {
    key: 'view-responses',
    href: (id) => `/course/${id}/dashboard/responses`,
    icon: checkSquareO,
    label: 'View Responses',
    caption: 'Basic information, quick statistics, and summarized results of form responses.',
    isInstructorAllowed: true,
  },
  // SECTION Attendances
  {
    key: 'attendance',
    href: (id) => `/course/${id}/dashboard/attendance`,
    icon: calendar,
    label: 'View Attendances',
    caption: 'Basic information, quick statistics, and summarized results of attendances.',
    isInstructorAllowed: true,
  },
  // SECTION Transactions
  {
    key: 'transaction',
    href: (id) => `/course/${id}/dashboard/transactions`,
    icon: creditCard,
    label: 'Course Transactions',
    caption: 'Transaction logs of this course.',
    isInstructorAllowed: false,
  },
];

export const MANAGE_LINK_LOOKUP: SidebarManagementLinkLookup = {
  courseManagement: COURSE_MANAGE_LINKS,
};
