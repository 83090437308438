// ANCHOR Model
import { createPropsSelectorModel, createSelector } from 'react-scoped-model';

// ANCHOR Utils
import { SidebarManagement } from '@lyon/utils/interface/sidebar';

interface IProps {
  content: SidebarManagement;
}

export const SidebarManageContent = createPropsSelectorModel<IProps>({
  displayName: 'Models.SidebarManageContent',
});

export const useSidebarManageContent = createSelector(
  SidebarManageContent,
  ({ content }) => content,
);
