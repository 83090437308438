// ANCHOR Next
import dynamic from 'next/dynamic';

// ANCHOR Base
import { Block } from 'baseui/block';
import { Spinner, SIZE } from 'baseui/spinner';
import { ALIGN, StyledNavigationList, StyledNavigationItem } from 'baseui/header-navigation';

// ANCHOR Graph State
import { useAuthState } from '@lyon/nodes/firebase/auth/auth-state-node';

// ANCHOR Models
import { PublicUserProfile } from '@lyon/scoped-models/profile/PublicUserProfile';

// ANCHOR Components
import { constant } from '@lyon/components/utils/constant';

const LyonNavBarSignInButton = dynamic(
  async () => {
    const mod = await import('./LyonNavBarSignInButton');

    return mod.LyonNavBarSignInButton;
  }, {
    ssr: false,
  },
);

const LyonNavBarTrainingButton = dynamic(
  async () => {
    const mod = await import('./LyonNavBarTrainingButton');

    return mod.LyonNavBarTrainingButton;
  }, {
    ssr: false,
  },
);

const LyonNotificationPopover = dynamic(
  async () => {
    const mod = await import('@lyon/components/notification/LyonNotificationPopover');

    return mod.LyonNotificationPopover;
  }, {
    ssr: false,
  },
);

const LyonNavBarAvatarPopover = dynamic(
  async () => {
    const mod = await import('./LyonNavBarAvatarPopover');

    return mod.LyonNavBarAvatarPopover;
  }, {
    ssr: false,
  },
);

const LyonNavBarRightContent = constant(() => {
  const user = useAuthState();

  if (typeof user === 'undefined') {
    return (
      <StyledNavigationItem>
        <Block marginRight="8px">
          <Spinner $size={SIZE.small} />
        </Block>
      </StyledNavigationItem>
    );
  }

  if (user) {
    return (
      <>
        <LyonNavBarTrainingButton />
        <LyonNotificationPopover />
        <PublicUserProfile.Provider id={user.uid}>
          <LyonNavBarAvatarPopover />
        </PublicUserProfile.Provider>
      </>
    );
  }

  return (
    <>
      <LyonNavBarTrainingButton />
      <LyonNavBarSignInButton />
    </>
  );
});

export const LyonNavBarRight = constant(() => (
  <StyledNavigationList $align={ALIGN.right}>
    <LyonNavBarRightContent />
  </StyledNavigationList>
));
