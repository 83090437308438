// ANCHOR React
import React, { memo } from 'react';

// ANCHOR Base
import { Block } from 'baseui/block';

// ANCHOR Models
import { PublicProviderSubscriptions } from '@lyon/scoped-models/profile/PublicProviderSubscriptions';

// ANCHOR Hooks
import { useAuthState } from '@lyon/nodes/firebase/auth/auth-state-node';

// ANCHOR Components
import { LyonSidebarSubscribedProviders } from './LyonSidebarSubscribedProviders';
import { LyonSidebarApplyProvider } from './LyonSidebarApplyProvider';
import { LyonSidebarFooter } from './LyonSidebarFooter';

// ANCHOR Styles
import { BLOCK } from './styles';

export const LyonSidebarMainContent = memo(() => {
  const user = useAuthState();

  return (
    <Block overrides={BLOCK}>
      {
        user && (
          <PublicProviderSubscriptions.Provider id={user.uid}>
            <LyonSidebarSubscribedProviders />
          </PublicProviderSubscriptions.Provider>
        )
      }
      <LyonSidebarApplyProvider />
      <LyonSidebarFooter />
    </Block>
  );
});
