// ANCHOR React
import React, { memo } from 'react';

// ANCHOR Base
import { withStyle } from 'baseui';
import { Block } from 'baseui/block';
import { ParagraphXSmall } from 'baseui/typography';

// ANCHOR Components
import { Skeleton } from '@lyon/components/utils/Skeleton';

// ANCHOR Styles
import { PARAGRAPH, PARAGRAPH_SKELETON } from './styles';

const ParagraphSkeleton = withStyle(Skeleton, PARAGRAPH_SKELETON);

interface IProps {
  description?: string;
}

export const SidebarManageDescription = memo(({ description }: IProps) => {
  if (description) {
    return (
      <ParagraphXSmall overrides={PARAGRAPH}>
        { description }
      </ParagraphXSmall>
    );
  }

  return (
    <Block>
      <ParagraphSkeleton />
      <ParagraphSkeleton />
    </Block>
  );
});
