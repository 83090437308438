// ANCHOR React
import React, { memo, useCallback } from 'react';

// ANCHOR Base
import { StyledLink } from 'baseui/link';
import { ARTWORK_SIZES, ListItem } from 'baseui/list';

// ANCHOR Next
import Link from 'next/link';

// ANCHOR Components
import { ProviderCheckManager } from '@lyon/scoped-models/ProviderCheckManager';
import { LyonSidebarMenuItemAvatar } from './LyonSidebarMenuItemAvatar';

// ANCHOR Styles
import { LINK, LIST_ITEM } from './styles';

interface IProps {
  id: string;
  avatar: string;
  name: string;
}

const LinkContainer = memo(({ id, avatar, name }: IProps) => {
  const isProvider = ProviderCheckManager.useSelector((state) => state.data);
  const artwork = useCallback(() => (
    <LyonSidebarMenuItemAvatar name={name} src={avatar} />
  ), [name, avatar]);

  return (
    <ListItem
      overrides={LIST_ITEM}
      artwork={artwork}
      artworkSize={ARTWORK_SIZES.SMALL}
    >
      <Link href={`/provider/${id}${isProvider ? '/manage' : ''}`} passHref>
        <StyledLink $style={LINK} title={name}>
          {name}
        </StyledLink>
      </Link>
    </ListItem>
  );
});

export const SidebarMenuItem = memo(({ id, avatar, name }: IProps) => (
  <ProviderCheckManager.Provider providerId={id}>
    <LinkContainer
      id={id}
      name={name}
      avatar={avatar}
    />
  </ProviderCheckManager.Provider>
));
