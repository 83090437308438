import { BlockOverrides } from 'baseui/block';
import { StyleObject } from 'styletron-react';

export const HEADING: BlockOverrides = {
  Block: {
    style: {
      fontWeight: 600,
      marginTop: '16px',
      marginBottom: 0,
      lineHeight: 1.15,
      display: '-webkit-box',
      WebkitLineClamp: 1,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
    },
  },
};

export const TITLE_SKELETON: StyleObject = {
  width: '48%',
  height: '16px',
  marginTop: '8px',
  marginBottom: 0,
};
