import { BlockOverrides } from 'baseui/block';

export const CONTAINER: BlockOverrides = {
  Block: {
    style: {
      paddingTop: '16px',
      paddingLeft: '24px',
      paddingRight: '24px',
      paddingBottom: '16px',
    },
  },
};
