// ANCHOR React
import React, { memo } from 'react';

// ANCHOR Model
import { MobileView } from '@lyon/scoped-models/MobileView';
import { SidebarVisibility } from '@lyon/scoped-models/sidebar/SidebarVisibility';

// ANCHOR Hooks
import { useIsomorphicEffect } from '@lyon/utils/hooks/useIsomorphicEffect';

// ANCHOR Components
import { LyonSidebarContainer } from './LyonSidebarContainer';
import { LyonSidebarPanel } from './LyonSidebarPanel';
import { LyonSidebarContent } from './LyonSidebarContent';

interface IProps {
  hideInitial?: boolean;
  useDrawer?: boolean;
}

export const LyonSidebar = memo(({
  hideInitial, useDrawer,
}: IProps) => {
  const isDesktop = MobileView.useSelector((state) => state.isDesktop);
  const [hide, show] = SidebarVisibility.useSelectors((state) => [
    state.hide,
    state.show,
  ]);

  useIsomorphicEffect(() => {
    if (hideInitial || useDrawer || !isDesktop) {
      hide();
    } else {
      show();
    }
  }, [hideInitial, useDrawer, isDesktop, hide, show]);

  if (isDesktop && !useDrawer) {
    return (
      <LyonSidebarContainer>
        <LyonSidebarContent />
      </LyonSidebarContainer>
    );
  }

  return (
    <LyonSidebarPanel>
      <LyonSidebarContent />
    </LyonSidebarPanel>
  );
});
