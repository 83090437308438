import { THEME } from '@lyon/utils/theme';
import { StyleObject } from 'styletron-react';

interface INavItem {
  $isActive: boolean
}

export const NAV_ITEM = (
  { $isActive }: INavItem,
): StyleObject => ({
  paddingTop: '16px',
  paddingRight: '8px',
  paddingBottom: '16px',
  paddingLeft: $isActive ? '20px' : '24px',
  opacity: $isActive ? '1' : '0.8',
  borderLeftWidth: $isActive ? '5px' : undefined,
  borderLeftStyle: $isActive ? 'solid' : undefined,
  borderLeftColor: $isActive ? THEME.colors.primary : undefined,
  backgroundColor: $isActive ? THEME.colors.darkmuted600 : undefined,

  ':hover': {
    backgroundColor: $isActive
      ? THEME.colors.darkmuted600
      : THEME.colors.darkmuted400,
  },
});

export const LINK = {
  textDecoration: 'none',
  cursor: 'pointer',
};
