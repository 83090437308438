// ANCHOR React
import { useCallback } from 'react';

// ANCHOR Graph Node
import { node } from 'graph-state';
import {
  useGraphNodeDispatch,
  useGraphNodeValue,
} from 'react-graph-state';

export const onBoardModalVisibilityNode = node({
  key: 'onBoardModalVisibility',
  get: false,
});

export function useOnBoardModalVisibility() {
  return useGraphNodeValue(onBoardModalVisibilityNode);
}

export function useOnBoardModalVisibilityDispatch() {
  return useGraphNodeDispatch(onBoardModalVisibilityNode);
}

export function useOnBoardModalVisibilityShow() {
  const dispatch = useOnBoardModalVisibilityDispatch();

  return useCallback(() => {
    dispatch(true);
  }, [dispatch]);
}

export function useOnBoardModalVisibilityHide() {
  const dispatch = useOnBoardModalVisibilityDispatch();

  return useCallback(() => {
    dispatch(false);
  }, [dispatch]);
}
