// ANCHOR Next
import dynamic from 'next/dynamic';

// ANCHOR Base
import {
  ALIGN,
  StyledNavigationList,
} from 'baseui/header-navigation';

// ANCHOR Models
import { MobileView } from '@lyon/scoped-models/MobileView';

// ANCHOR Components
import { constant } from '@lyon/components/utils/constant';

const LyonNavBarSearch = dynamic(
  async () => {
    const mod = import('./LyonNavBarSearch');
    return (await mod).LyonNavBarSearch;
  },
);

export const LyonNavBarCenter = constant(() => {
  const isDesktop = MobileView.useSelector((state) => state.isDesktop);

  return (
    <>
      <StyledNavigationList $align={ALIGN.center}>
        <LyonNavBarSearch />
      </StyledNavigationList>
      {isDesktop && <StyledNavigationList $align={ALIGN.center} />}
    </>
  );
});
