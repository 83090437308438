// ANCHOR Base
import { styled } from 'baseui';
import { styletron } from '@lyon/utils/styletron';

const slide = styletron.renderKeyframes({
  '0%': {
    transform: 'translateX(-100%)',
  },
  '50%': {
    transform: 'translateX(-100%)',
  },
  '100%': {
    transform: 'translateX(100%)',
  },
});

export const Skeleton = styled('span', {
  position: 'relative',
  backgroundColor: '#2E2C35',
  overflow: 'hidden',
  display: 'inline-block',

  ':after': {
    backgroundImage: `linear-gradient(
      90deg,
      #2E2C35 0%,
      #2E2C35 25%,
      #585464 50%,
      #2E2C35 75%,
      #2E2C35 100%
    )`,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    content: '',
    transform: 'translateX(-100%)',

    animationName: slide,
    animationDuration: '2s',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
    cursor: 'wait',
  },
});
