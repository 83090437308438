// ANCHOR React
import React, { memo } from 'react';

// ANCHOR Next
import { useRouter } from 'next/router';

// ANCHOR Models
import { CourseData } from '@lyon/scoped-models/CourseData';

// ANCHOR Utils
import { getQueryPID } from '@lyon/utils/extras/getQueryPID';

// ANCHOR Components
import { LyonSidebarCourseManageInfoContent } from './LyonSidebarCourseManageInfoContent';

export const LyonSidebarCourseManageInfo = memo(() => {
  const { pid } = useRouter().query;
  const courseId = getQueryPID(pid);

  return (
    <CourseData.Provider id={courseId}>
      <LyonSidebarCourseManageInfoContent />
    </CourseData.Provider>
  );
});
