// ANCHOR React
import React from 'react';

// ANCHOR Base
import { Block } from 'baseui/block';

// ANCHOR Styles
import { BLOCK } from './styles';

interface ISidebarSection {
  children: React.ReactNode;
}

export const SidebarSection = ({ children }: ISidebarSection) => (
  <Block overrides={BLOCK}>
    { children }
  </Block>
);
