// ANCHOR  React
import React from 'react';

// ANCHOR Base
import { Block } from 'baseui/block';

// ANCHOR Models
import { SidebarVisibility } from '@lyon/scoped-models/sidebar/SidebarVisibility';

// ANCHOR Styles
import { BLOCK } from './styles';

interface ILyonSidebarContainerProps {
  children?: React.ReactNode;
}

export function LyonSidebarContainer({ children }: ILyonSidebarContainerProps) {
  const value = SidebarVisibility.useSelector((state) => state.state);

  if (value) {
    return (
      <Block overrides={BLOCK}>
        { children }
      </Block>
    );
  }

  return null;
}
