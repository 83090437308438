// ANCHOR React
import React, { memo } from 'react';

// ANCHOR Base
import { ParagraphSmall } from 'baseui/typography';

// ANCHOR styles
import { PADDING } from './styles';

// ANCHOR Constants
import { POPOVER } from './constants';

export const LyonSidebarPopoverContent = memo(() => (
  <ParagraphSmall overrides={PADDING}>{POPOVER}</ParagraphSmall>
));
