// ANCHOR React
import React, { memo } from 'react';

// ANCHOR Model
import { MobileView } from '@lyon/scoped-models/MobileView';
import { useSidebarContent } from '@lyon/scoped-models/sidebar/SidebarContent';

// ANCHOR Hooks
import { useAuthRequired } from '@lyon/nodes/firebase/auth/auth-required-node';

// ANCHOR Components
import { Scrollbar } from '@lyon/components/utils/Scrollbar';
import { LyonSidebarMainContent } from './LyonSidebarMainContent';

// ANCHOR Constants
import { SIDEBAR_CONTENT_LOOKUP } from './constants';

export const LyonSidebarContent = memo(() => {
  const sidebarContent = useSidebarContent();
  const isMobile = MobileView.useSelector((state) => state.isMobile);
  const isSignedIn = useAuthRequired();

  return (
    <Scrollbar>
      {
        (isMobile || !isSignedIn)
          ? <LyonSidebarMainContent />
          : SIDEBAR_CONTENT_LOOKUP[sidebarContent]
      }
    </Scrollbar>
  );
});
