// ANCHOR React
import React, { memo } from 'react';

// ANCHOR Base
import { Avatar } from 'baseui/avatar';

// ANCHOR styles
import { AVATAR_SIZE } from './styles';

interface IProps {
  name: string;
  src: string;
}

export const LyonSidebarMenuItemAvatar = memo(({ name, src }: IProps) => (
  <Avatar
    name={name}
    size={AVATAR_SIZE}
    src={src}
  />
));
