// ANCHOR React
import React from 'react';

// ANCHOR Interfaces
import { SidebarManagementLookup } from '@lyon/utils/interface/sidebar';

// ANCHOR Components
import { LyonSidebarCourseManageInfo } from './LyonSidebarCourseManageInfo';

export const SIDEBAR_MANAGEMENT_LOOKUP: SidebarManagementLookup = {
  courseManagement: <LyonSidebarCourseManageInfo />,
};
